import React, { useEffect } from 'react'
import { BoxProps, useMediaQuery } from '@mui/material'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import Box from '@mui/material/Box'
import ArrowBackIcon from '@mui/icons-material/ArrowBackRounded'
import CheckIcon from '@mui/icons-material/Check'
import Stack from '@mui/material/Stack'
import Divider from '@mui/material/Divider'
import { useWindowSize } from 'react-use'
import log from 'loglevel'
import UserInteractivityMenuComponent, {
  MobileMenuComponent
} from 'components/organisms/panels/user-interactivity-menu/user-interactivity-menu'
import {
  AMAZETHU_V2_TESTBED,
  AUDIO_IN_AUDIO_OUT,
  AUDIO_IN_TEXT_OUT,
  NOTIFICATION_MSG_SYSTEM_ERROR,
  IS_PROD_ENV,
  NOTIFICATION_MSG_NETWORK_ERROR,
  NOTIFICATION_TITLE_SYSTEM_ERROR,
  NOTIFICATION_TITLE_NETWORK_ERROR,
  PAGE_USER_PROGRAM_SELECT
} from 'datastore/utils/constants'
import ErrorDialogComponent from 'components/molecules/popups/error-dialog'
import V2RecordButtonComponent from 'components/atoms/buttons/v2-record-audio-button'
import { updateConfigOptionsAction } from 'datastore/slices/v2-program-controller'
import programApi from 'datastore/apis/program-api'
import { useNavigate } from 'react-router'
import { PopupTypeEnum, V2ProgramItemInterface } from '../../../../types'
import {
  useStoreDispatch,
  useStoreSelector
} from '../../../../datastore/config/hooks'
import { theme } from '../../../atoms/styles/theme'
import {
  changePageService,
  didBackendAPIRaiseException,
  showPopupService,
  updateHasUpdatedTestbedModels,
  updateInteractionConfigOptionsAction,
  updateIsMobileMenuOpen,
  updateSafeInteractionRoute
} from '../../../../datastore/slices/app-controller'
import { ViewType } from './user-block-interact-record-feedback'
import { INTERACTION_OPTIONS } from './user-block-interact-setup'
import AudioInTextOutChatComponent from './v2-user-block-interact-audio-in-text-out'
import AudioInAudioOutChatComponent from './v2-user-block-interact-audio-in-audio-out'

let bottomBarHeight

const BackButtonComponent = ({
  height,
  'aria-label': ariaLabel,
  width,
  minWidth,
  maxWidth,
  borderRadius
}: BoxProps) => {
  const dispatch = useStoreDispatch()

  return (
    <IconButton
      onClick={() => {
        log.info('*** UserBlockInteract::back ***')

        dispatch(changePageService(`${PAGE_USER_PROGRAM_SELECT}`))
        INTERACTION_OPTIONS.setAudioUrls([])
      }}
      sx={{
        bgcolor: '#D8D8D8',
        color: '#323232',
        fontSize: '22px',
        flexDirection: 'column',
        borderRadius: borderRadius || '8px',
        alignItems: 'center',
        justifyContent: 'center',
        width,
        minWidth,
        maxWidth,
        height,
        ':hover': {
          'background-color': '#bababa'
        }
      }}
      aria-label={ariaLabel}
      className="back-button">
      <ArrowBackIcon sx={{ color: 'neutral_dark' }} />
      {!ariaLabel ? null : (
        <Stack
          sx={{
            color: '#323232',
            fontSize: '27px',
            height: '84px',
            justifyContent: 'center'
          }}>
          {ariaLabel}
        </Stack>
      )}
    </IconButton>
  )
}

const ApplyChangesButtonComponent = ({
  height,
  width,
  minWidth,
  maxWidth,
  borderRadius
}: BoxProps) => {
  const dispatch = useStoreDispatch()
  const handleToggle = () => {
    dispatch(updateConfigOptionsAction())
    dispatch(updateInteractionConfigOptionsAction())
    dispatch(updateIsMobileMenuOpen(false))
    dispatch(updateHasUpdatedTestbedModels(true))
  }

  return (
    <IconButton
      onClick={handleToggle}
      className="apply-changes-button"
      sx={{
        bgcolor: '#D8D8D8',
        color: '#323232',
        fontSize: '22px',
        flexDirection: 'column',
        borderRadius: borderRadius || '8px',
        alignItems: 'center',
        justifyContent: 'center',
        width,
        minWidth,
        maxWidth,
        height,
        ':hover': {
          'background-color': '#bababa'
        }
      }}>
      <CheckIcon sx={{ color: 'neutral_dark' }} />
    </IconButton>
  )
}

interface ProgramDetailsComponentProps {
  height: number
}

const ProgramDetailsComponent = ({ height }: ProgramDetailsComponentProps) => {
  const currentProgram: V2ProgramItemInterface = useStoreSelector(
    (storeState) => storeState.v2ProgramController.currentProgram
  ) as V2ProgramItemInterface

  return (
    <Stack
      direction="row"
      height={height}
      padding="4px"
      alignItems="center"
      gap="16px">
      <Typography variant="h2" color={theme.palette.neutral_dark.dark}>
        {currentProgram.name}
      </Typography>
    </Stack>
  )
}

const BottomBarComponent = () => {
  const { width: pageWidth, height: pageHeight } = useWindowSize()
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'))
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'))
  const isLandScapeMode = pageWidth > pageHeight

  const currInteractionType = useStoreSelector(
    (storeState) => storeState.appController.currInteractionType
  )

  const runtimeAppEnv = useStoreSelector(
    (storeState) => storeState.appController.runtimeAppEnv
  )

  const isMobileMenuOpen = useStoreSelector(
    (storeState) => storeState.appController.isMobileMenuOpen
  )

  let barHeight: number
  let itemHeight: number
  let paddingX: number
  if (isDesktop) {
    barHeight = 119
    itemHeight = 89
    paddingX = 50
  } else if (isTablet) {
    barHeight = isLandScapeMode ? 109 : 119
    itemHeight = isLandScapeMode ? 72 : 89
    paddingX = isLandScapeMode ? 50 : 32
  } else {
    barHeight = isLandScapeMode ? 56 : 85
    itemHeight = isLandScapeMode ? 42 : 52
    paddingX = isLandScapeMode ? 24 : 16
  }

  bottomBarHeight = barHeight

  return (
    <Box width="100%" sx={{ backgroundColor: 'white' }} marginTop="0">
      <Divider />
      <Stack
        direction="row"
        justifyContent={isDesktop ? 'center' : 'space-between'}
        height={barHeight}
        paddingX={`${paddingX}px`}
        paddingY={`${Math.round(itemHeight / 5)}px`}
        alignItems="center">
        {!isDesktop && runtimeAppEnv === AMAZETHU_V2_TESTBED ? (
          <UserInteractivityMenuComponent
            height={itemHeight}
            width={(itemHeight * 3) / 2}
          />
        ) : null}

        <ProgramDetailsComponent height={itemHeight} />

        {currInteractionType === AUDIO_IN_TEXT_OUT && (
          <V2RecordButtonComponent />
        )}

        {!isDesktop && !isMobileMenuOpen && (
          <BackButtonComponent
            height={itemHeight}
            width={(itemHeight * 3) / 2}
          />
        )}
        {!isDesktop &&
          isMobileMenuOpen &&
          runtimeAppEnv === AMAZETHU_V2_TESTBED && (
            <ApplyChangesButtonComponent
              height={itemHeight}
              width={(itemHeight * 3) / 2}
            />
          )}
      </Stack>
    </Box>
  )
}

const UserBlockInteractChildComponent = () => {
  const dispatch = useStoreDispatch()
  const isSafeInteractionRoute = useStoreSelector(
    (storeState) => storeState.appController.safeInteractionRoute
  )

  useEffect(() => {
    if (!isSafeInteractionRoute) {
      dispatch(changePageService(`${PAGE_USER_PROGRAM_SELECT}`))
    }
  }, [])

  useEffect(() => {
    dispatch(updateSafeInteractionRoute(false))
  }, [])

  const isDesktop = useMediaQuery(theme.breakpoints.up('md'))

  const currInteractionType = useStoreSelector(
    (storeState) => storeState.appController.currInteractionType
  )

  const runtimeAppEnv = useStoreSelector(
    (storeState) => storeState.appController.runtimeAppEnv
  )

  let interactivityTypeComponent
  if (currInteractionType === AUDIO_IN_AUDIO_OUT) {
    interactivityTypeComponent = <AudioInAudioOutChatComponent />
  } else {
    interactivityTypeComponent = <AudioInTextOutChatComponent />
  }

  const isMobileMenuOpen = useStoreSelector(
    (storeState) => storeState.appController.isMobileMenuOpen
  )

  return (
    <Stack id="ds" flex="1" height="100%" direction="row">
      {isDesktop && runtimeAppEnv === AMAZETHU_V2_TESTBED ? (
        <UserInteractivityMenuComponent
          borderRadius="0"
          width="13%"
          maxWidth="225px"
          minWidth="150px"
        />
      ) : null}

      <Stack
        direction="column"
        alignItems="center"
        flex="1"
        sx={{
          width: '100%',
          flex: 1,
          flexDirection: 'column',
          overflow: 'hidden'
        }}>
        <Stack
          spacing={1}
          sx={{
            flex: 1,
            overflow: 'hidden',
            position: 'relative',
            height: '100%',
            width: '100%',
            gap: 0,
            '& > :not(style)~:not(style)': {
              margin: 0
            }
          }}>
          <Stack
            sx={{
              flex: 1,
              height: '100%',
              position: 'relative',
              overflow: 'hidden'
            }}>
            <Stack
              sx={{
                flex: 1,
                height: '100%',
                overflow: 'auto',
                p: 2
              }}>
              {!IS_PROD_ENV ? (
                interactivityTypeComponent
              ) : (
                <AudioInTextOutChatComponent />
              )}
            </Stack>

            {!isDesktop && (
              <Box
                sx={{
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%',
                  flex: 1,
                  position: 'absolute',
                  top: 0,
                  backgroundColor: '#bababa',
                  display: isMobileMenuOpen ? 'flex' : 'none',
                  transform: isMobileMenuOpen
                    ? 'translateX(0)'
                    : 'translateX(-100vw)',
                  width: '100%',
                  zIndex: 99,
                  transition: 'all 0.2s ease-in-out',
                  overflow: 'auto'
                }}>
                <MobileMenuComponent />
              </Box>
            )}
          </Stack>
          <Stack position="sticky" bottom="0">
            <BottomBarComponent />
          </Stack>
        </Stack>
      </Stack>
      {!isDesktop ? null : (
        <BackButtonComponent
          borderRadius="0"
          width="13%"
          maxWidth="225px"
          minWidth="150px"
          aria-label="GO BACK"
        />
      )}
    </Stack>
  )
}

const V2UserBlockInteractComponent = () => {
  const [viewType, setViewType] = React.useState<ViewType>(
    INTERACTION_OPTIONS.value
  )
  INTERACTION_OPTIONS.value = viewType

  const dispatch = useStoreDispatch()

  const activePopup = useStoreSelector(
    (storeState) => storeState.appController.currPopup
  )

  const handleBrowserBackButton = () => {
    if (activePopup.type !== PopupTypeEnum.none) {
      dispatch(
        showPopupService({ type: PopupTypeEnum.none, data: { result: null } })
      )
    } else {
      dispatch(changePageService(`${PAGE_USER_PROGRAM_SELECT}`))
    }
  }

  // Handles all first load.
  useEffect(() => {
    window.onpopstate = handleBrowserBackButton
    return () => {
      window.onpopstate = null
    }
  }, [activePopup])

  return (
    <Box position="relative" width="100%" height="100%">
      <UserBlockInteractChildComponent />
    </Box>
  )
}

export default V2UserBlockInteractComponent
