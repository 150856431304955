/* eslint-disable camelcase */

export const PAGE_USER_AUTH = 'auth'
export const PAGE_ADMIN_AUTH = 'admin'
export const PAGE_ADMIN_PROGRAM_SELECT = 'admin/programs'
export const PAGE_ADMIN_BLOCK_DESIGN = 'admin/block/design'
export const PAGE_ADMIN_BLOCK_PREVIEW = 'admin/block/preview'
export const PAGE_ADMIN_BLOCK_SETTINGS = 'admin/block/settings'
export const PAGE_USER_PROGRAM_SELECT = 'programs'
export const PAGE_USER_BLOCK_SELECT = 'blocks'
export const PAGE_USER_BLOCK_INTERACT = 'session'

export const LABEL_DESIGN = 'Conversation Logic'
export const LABEL_PREVIEW = 'Preview'
export const LABEL_VIEW_PROGRAMS = 'See All Programs'
export const LABEL_CREATE_PROGRAM = 'Create New Program'

export const ARENA_PANEL_STATE = 'state'
export const ARENA_PANEL_OPTION = 'option'
export const ARENA_PANEL_PREVIEW = 'preview'
export const ARENA_PANEL_NONE = 'none'

export const TITLE_P_BLOCK_NOTIFICATION = 'Cannot Preview Block'
export const MESSAGE_P_BLOCK_NOTIFICATION =
  'You cannot preview the block without saving or publishing'
export const TITLE_AUTH_ERROR_NOTIFICATION = 'Access Denied'
export const MESSAGE_AUTH_ERROR_NOTIFICATION =
  'Email address provided has not been granted access to the platform'
export const NOTIFICATION_TITLE_FEEDBACK_REQUEST = 'Feedback Requested'
export const NOTIFICATION_FEEDBACK_REQUEST_ACTION_NO = `LATER`
export const NOTIFICATION_FEEDBACK_REQUEST_ACTION_YES = `YES`
export const NOTIFICATION_FEEDBACK_REQUEST_ACTION_CANCEL = `CANCEL`
export const NOTIFICATION_FEEDBACK_REQUEST_ACTION_MIC_ON = `ALLOW`
export const NOTIFICATION_MSG_FEEDBACK_REQUEST =
  'Thanks for using our app! Your feedback is important to us. Please take a moment to tell us what you think'
export const NOTIFICATION_TITLE_MIC_DISABLED = 'Microphone Access Required'
export const NOTIFICATION_MSG_MIC_DISABLED =
  'This app needs access to your microphone for speech input. Please grant us access by tapping "Allow" below.'
export const NOTIFICATION_TITLE_MIC_DENIED = 'Microphone Permission Denied'
export const NOTIFICATION_MSG_MIC_DENIED =
  'The application is unable to successfully request microphone permission access from your device. Please check the settings of your device to ensure that our website application can request for microphone permission access.'
export const NOTIFICATION_TITLE_DESKTOP_NOT_SUPPORTED = 'Not Supported'
export const NOTIFICATION_MSG_DESKTOP_NOT_SUPPORTED =
  'Currently, this application is only supported on mobile devices.'
export const NOTIFICATION_TITLE_NETWORK_ERROR = 'Network Error'
export const NOTIFICATION_MSG_NETWORK_ERROR =
  'We are having trouble reaching the network. Please check your internet and try again.'
export const NOTIFICATION_TITLE_SYSTEM_ERROR = 'System Error'
export const NOTIFICATION_MSG_SYSTEM_ERROR =
  'Unfortunately our system encountered an error while trying to process an adequate response. Please select the program again to continue learning, sorry for the inconvenience.'

// site constants
export const TOAST_DURATION = 5000
export const ORIGIN = window.location.origin
export const TOKEN_COOKIE = 'TOKEN'
export const USER_COOKIE = 'USER'
export const COOKIE_EXPIRE = 60 * 60 * 24 * 365
export const PATH_COOKIE = '/'
export const SELECTIVE = 'selective'
export const RANDOM = 'cocktail'
export const GROUP = 'group'
export const SEGMENT = 'segment'
export const DATE_FORMAT = 'DD-MM-YYYY'
export const TIME_FORMAT = 'hh:mm A'
export const DURATION_FORMAT = 'HH [h ]mm [min]'
export const TIME_ZONE = 'UTC'
export const MINIMUM_RECORD_LENGTH_MILLIS = 3000

// version constants
export const AMAZETHU_V1 = 'v1-live'
export const AMAZETHU_V2_TESTBED = 'v2-testbed'
export const AMAZETHU_V2_LIVE = 'v2-live'

// v2 Interaction type constants
export const AUDIO_IN_AUDIO_OUT = 'audio-in-audio-out'
export const TEXT_IN_AUDIO_OUT = 'text-in-audio-out'
export const TEXT_IN_TEXT_OUT = 'text-in-text-out'
export const AUDIO_IN_TEXT_OUT = 'audio-in-text-out'

export const FEEDBACK_FORM_URL = 'https://forms.gle/rTHRYdQBEqT8SiW26'

// Environments
export enum AppEnvironmentEnum {
  stag = 'STAG',
  dev = 'DEV',
  prod = 'PROD'
}

export const IS_STAG_ENV =
  process.env.REACT_APP_ENVIRONMENT === AppEnvironmentEnum.stag
export const IS_PROD_ENV =
  process.env.REACT_APP_ENVIRONMENT === AppEnvironmentEnum.prod
export const IS_DEV_ENV =
  process.env.REACT_APP_ENVIRONMENT === AppEnvironmentEnum.dev

export enum FeatureFlagsEnum {
  playBotUtterance = 'feature_playback_text_utterance',
  disableUserAuthentication = 'feature_disable_user_authentication',
  enforceUserAuthenticationAfterXDaysFree = 'feature_enforce_user_authentication_after_x_days_free',
  enforceUserSubscriptionAfterXDaysFree = 'feature_enforce_user_subscription_after_x_days_free'
}

export const { REACT_APP_PROD_LANDING_PAGE_URL, REACT_APP_PROD_WEBAPP_URL } =
  process.env
