import React from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  IconButton,
  Typography
} from '@mui/material'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import { NOTIFICATION_TITLE_SYSTEM_ERROR } from 'datastore/utils/constants'

interface ErrorDialogPropsInterface {
  open: boolean
  errorMessage: string
  errorTitle: string
  onClose: () => void
}

const ErrorDialogComponent: React.FC<ErrorDialogPropsInterface> = ({
  open,
  errorMessage,
  errorTitle = NOTIFICATION_TITLE_SYSTEM_ERROR,
  onClose
}) => {
  return (
    <Dialog
      open={open}
      onClose={(_, reason) => reason !== 'backdropClick' && onClose()}
      aria-labelledby="error-dialog-title"
      aria-describedby="error-dialog-description"
      sx={{ '& .MuiBackdrop-root': { backgroundColor: 'rgba(0, 0, 0, 0.9)' } }}>
      <DialogTitle>
        <IconButton aria-label="error" size="large" disabled>
          <ErrorOutlineIcon fontSize="inherit" color="error" />
        </IconButton>
        <Typography variant="h3" component="span" sx={{ ml: 1 }}>
          {errorTitle}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{errorMessage}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          color="primary"
          variant="contained"
          id="system-error-dialog-ok-btn">
          OK
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ErrorDialogComponent
