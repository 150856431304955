import useTextInteraction from 'hooks/use-audio-to-text-interactions'
import React from 'react'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { AMAZETHU_V2_TESTBED } from 'datastore/utils/constants'
import LoaderComponent from 'components/atoms/loaders/loader-component/loader-component'

import Box from '@mui/system/Box'
import InfoDialogComponent from 'components/molecules/popups/v2-info-dialog'
import { useStoreSelector } from 'datastore/config/hooks'
import V2UserAndBotAudioPlayerComponent from '../../../molecules/media/audio-players/v2-user-and-bot-audio-player'

const AudioInTextOutChatComponent: React.FC = () => {
  const {
    messages,
    isLoading,
    messagesEndRef,
    isPlaying,
    shouldEnablePlaybackUtteranceFeature
  } = useTextInteraction()

  const runtimeAppEnv = useStoreSelector(
    (storeState) => storeState.appController.runtimeAppEnv
  )

  const logConfig = (messageAction: { sender: string; text: string }) => {
    return (
      <Box
        sx={{
          pt: '32px',
          color: messageAction.sender === 'backend-log' ? 'grey' : 'purple',
          alignSelf: 'center',
          fontStyle: 'italic',
          fontSize: '12px',
          textAlign: 'center'
        }}>
        {messageAction.sender === 'backend-log'
          ? 'Config Applied'
          : 'Testbed Config'}
        <br />
        {messageAction.text}
      </Box>
    )
  }

  return (
    <>
      {messages.map((message) => (
        <>
          {message.actions.map((messageAction, idx) =>
            messageAction.sender === 'backend-log' ||
            messageAction.sender === 'testbed-log' ? (
              runtimeAppEnv === AMAZETHU_V2_TESTBED && logConfig(messageAction)
            ) : (
              <div
                style={{
                  alignSelf:
                    messageAction.sender === 'user' ? 'flex-end' : 'flex-start',
                  maxWidth: '80%',
                  width: 'fit-content',
                  wordWrap: 'break-word',
                  overflowWrap: 'break-word',
                  whiteSpace: 'pre-wrap',
                  marginBottom: '10px'
                }}>
                <Box
                  sx={{
                    p: 1.5,
                    borderRadius:
                      messageAction.sender === 'user'
                        ? '10px 0px 10px 10px'
                        : '0 10px 10px 10px',
                    color: 'black',
                    backgroundColor:
                      messageAction.sender === 'user' ? '#D7BFFF' : '#e0e0e0',
                    textAlign:
                      messageAction.sender === 'user' ? 'right' : 'left',
                    position: 'relative',
                    marginTop: 2
                  }}>
                  <Typography
                    align={messageAction.sender === 'user' ? 'right' : 'left'}
                    className={
                      messageAction.sender === 'user'
                        ? 'user-message'
                        : 'bot-message'
                    }
                    sx={{ color: 'black' }}>
                    {messageAction.text}
                  </Typography>
                  {shouldEnablePlaybackUtteranceFeature &&
                    messageAction.audioUrl && (
                      <Box
                        sx={{
                          position: 'absolute',
                          bottom: -20,
                          left: messageAction.sender === 'bot' ? 12 : undefined,
                          right:
                            messageAction.sender === 'user' ? 12 : undefined,
                          zIndex: 0
                        }}>
                        <V2UserAndBotAudioPlayerComponent
                          isPlaying={isPlaying}
                          audioUrl={messageAction.audioUrl}
                        />
                      </Box>
                    )}
                </Box>
              </div>
            )
          )}
          {runtimeAppEnv === AMAZETHU_V2_TESTBED && (
            <Stack
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 1,
                paddingTop: 2,
                paddingBottom: 2,
                flexWrap: 'wrap'
              }}>
              {message.userUtteranceEval && (
                <InfoDialogComponent
                  data={[message.userUtteranceEval]}
                  buttonName="Utterance Eval"
                  dialogTitle="Utterance Eval"
                />
              )}
              {message.botLogs && (
                <InfoDialogComponent
                  data={[message.botLogs]}
                  buttonName="Bot Logs"
                  dialogTitle="Bot Logs"
                />
              )}
              {message.parserErrors && (
                <InfoDialogComponent
                  data={[message.parserErrors]}
                  buttonName="Parser Errors"
                  dialogTitle="Parser Errors"
                />
              )}
            </Stack>
          )}
        </>
      ))}
      <div ref={messagesEndRef} />
      <LoaderComponent isLoading={isLoading} />
    </>
  )
}

export default AudioInTextOutChatComponent
