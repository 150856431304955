import React, { useState } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography
} from '@mui/material'
import log from 'loglevel'

interface StagingAuthModalComponentPropsInterface {
  open: boolean
  onSubmit: (password: string) => void
}

const StagingAuthModalComponent: React.FC<
  StagingAuthModalComponentPropsInterface
> = ({ open, onSubmit }) => {
  const [password, setPassword] = useState('')
  const [error, setError] = useState<string | null>(null)

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value)
  }

  const handleSubmit = () => {
    if (password.trim() === '') {
      setError('Please enter a password.')
      return
    }

    if (password !== process.env.REACT_APP_STAGING_PASSCODE) {
      setError('Incorrect code')
      return
    }
    onSubmit(password)
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      handleSubmit()
    }
  }

  return (
    <Dialog
      open={open}
      onClose={() => {
        log.info('Staging Auth Modal Closed')
      }}
      sx={{ '& .MuiBackdrop-root': { backgroundColor: 'rgba(0, 0, 0)' } }}>
      <DialogTitle>Passcode Required</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="Enter code"
          type="password"
          fullWidth
          value={password}
          onChange={handlePasswordChange}
          error={!!error}
          onKeyDown={handleKeyDown}
        />
        {error && (
          <Typography variant="h5" color="error" gutterBottom>
            {error}
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSubmit} color="primary">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default StagingAuthModalComponent
