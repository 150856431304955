import React from 'react'
import { Modal, Box, Typography, Stack } from '@mui/material'
import { styled } from '@mui/system'
import { theme } from 'components/atoms/styles/theme'
import styles from 'components/molecules/popups/popup.styles'
import {
  NOTIFICATION_MSG_DESKTOP_NOT_SUPPORTED,
  NOTIFICATION_TITLE_DESKTOP_NOT_SUPPORTED
} from 'datastore/utils/constants'

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 300,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  textAlign: 'center'
}

const StyledModal = styled(Modal)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
})

interface NotSupportedModalProps {
  open: boolean
}

const DesktopNotSupportedComponent: React.FC<NotSupportedModalProps> = ({
  open
}) => {
  return (
    <StyledModal
      sx={{ zIndex: -1 }}
      open={open}
      onClose={() => {
        console.log('close')
      }} // Prevent closing
      disableEscapeKeyDown // Disable closing on escape key
      aria-labelledby="not-supported-title"
      aria-describedby="not-supported-description">
      <Stack sx={{ ...styles.stackContainer, width: 350 }}>
        <Box sx={{ ...styles.titleBox, bgcolor: theme.palette.warning.light }}>
          <Typography
            id="form-title"
            variant="h4"
            color={theme.palette.neutral_dark.dark}>
            {NOTIFICATION_TITLE_DESKTOP_NOT_SUPPORTED}
          </Typography>
        </Box>

        <Stack spacing={2} sx={styles.textInputContainer}>
          <Box sx={styles.textInput}>
            <Typography variant="h4" color={theme.palette.neutral_dark.dark}>
              {NOTIFICATION_MSG_DESKTOP_NOT_SUPPORTED}
            </Typography>
          </Box>
        </Stack>
      </Stack>
    </StyledModal>
  )
}

export default DesktopNotSupportedComponent
