import React from 'react'
import ReactDOM from 'react-dom'
import { FirebaseAppProvider } from 'reactfire'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'

import { ThemeProvider, GlobalStyles } from '@mui/material/'
import CssBaseline from '@mui/material/CssBaseline'

import { theme } from 'components/atoms/styles/theme'
import reportWebVitals from 'app/report-web-vitals'
import App from 'app/app'
// import Playground from 'app/Playground'

import { store, persistor } from 'datastore/config/store'
import swDev from 'sw-dev'
import VersionFlag from 'components/molecules/dropdowns/version-flag'
import AuthenticationComponent from 'components/molecules/popups/user-authentication/user-authentication'

const GLOBAL_STYLES = (
  <GlobalStyles
    styles={{
      html: { margin: 0, padding: 0, height: '100%' },
      body: {
        margin: 0,
        padding: 0,
        height: '100%'
      },
      '#root': { height: '100%' }
    }}
  />
)

ReactDOM.render(
  <React.StrictMode>
    <FirebaseAppProvider
      firebaseConfig={JSON.parse(`${process.env.REACT_APP_FIREBASE_CONFIG}`)}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            {GLOBAL_STYLES}
            <BrowserRouter>
              <VersionFlag>
                <AuthenticationComponent>
                  <App />
                </AuthenticationComponent>
              </VersionFlag>
            </BrowserRouter>
          </ThemeProvider>
        </PersistGate>
      </Provider>
    </FirebaseAppProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(Console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
swDev()
